import React from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    List,
    ListItem,
    ListItemText,
    Button,
    Typography
} from '@mui/material';

const DialogoUsuarios = ({ open, onClose, tramo, nombre_tramo }) => {
    console.log('nombre_tramo', nombre_tramo);
    console.log(tramo);
    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>{nombre_tramo === 'izquierdo' ? 'Usuarios Izquierdos' : 'Usuarios Derechos'}</DialogTitle>
            <DialogContent>
                <List>

                    {tramo && tramo.length > 0 && tramo.map((usuario, index) => (
                        <ListItem key={index} >
                            <Typography sx={{ borderBottom: '1px solid #00000040', width: '100%' }}>{index + 1}. {usuario}</Typography>
                        </ListItem>
                    ))}

                </List>
                <Button onClick={onClose} color="primary">
                    Cerrar
                </Button>
            </DialogContent>
        </Dialog>
    );
};

export default DialogoUsuarios;