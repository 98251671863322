/**************************************************
 * Nombre:       Dialogo_Producto_Empresaria
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import { Button, Grid } from "@mui/material";
import { useFormulario } from "../../Modulo_formularios/Hooks/useFormulario";
import { TaskAlt } from "@mui/icons-material";
import { useLoaders } from "../../Modulo_Loaders/Hooks/useLoaders";
import logo from '../../Recursos/logo_blanco.svg'
import { guardarDoc } from "../../Servicios/BD/guardarDoc";
import { actualizarDoc } from "../../Servicios/BD/actualizarDoc";
import Formulario_Compra_Acciones from "../Formularios/Formulario_Compra_Acciones";
import { borrarDoc } from "../../Servicios/BD/borrarDoc";
import { obtenerDoc } from "../../Servicios/BD/obtenerDoc";
import { contarPropiedadSumaWheres } from "../../Servicios/BD/Contadores/contarPropiedadSumaWheres";
import { doc, updateDoc, where } from "firebase/firestore";
import { fire } from "../../fire";

const Dialogo_Compra_Acciones = ({ realizado, datos, cerrar }) => {
    const { props, obtenerEntidad } = useFormulario({ valoresDefecto: datos })
    const { Cargador, abrirCargador, cerrarCargador } = useLoaders({
        logo: logo,
    })


    const guardarCambios = () => {

        obtenerEntidad().then((entidad) => {
            let obj = entidad
            //  obj.fecha = new Date().getTime()
            abrirCargador('Guardando Cambios')

            if (entidad.estado === 'Rechazada') {
                eliminarCompra(obj)
            } else if (entidad.estado === 'Activa') {
                let idUsuario = obj.idUsuario
                let valor = obj.valor
                console.log(`ID USUARIO: ${idUsuario} VALOR: ${valor}`)
                // console.log(obj);

                guardarDoc('compraAcciones', obj).then((des) => {
                    if (des.res) {
                        // Ademas se va a comisionar si tiene a su padre, ademas de agregarle puntos a sus ancestros
                        console.log('Se procede a verificar comisiones y puntos')
                        validarComisiones(idUsuario, valor);
                        // Esperar 5 segundos para actualizar el usuario
                        setTimeout(() => {
                            actualizarUsuario(obj);
                            // cerrarCargador()
                        }, 3000)
                    } else {
                        cerrarCargador()
                        alert('Error al subir datos, por favor intentelo nuevamente')
                    }
                })
            } else {
                cerrarCargador()
                console.log('No se ha realizado ninguna accion')
            }
        })
    }

    ///////////////////////////////////////////////Comisiones y puntos///////////////////

    const validarComisiones = async (idUsuario, valor) => {
        if (idUsuario === "" || !idUsuario || valor === 0 || !valor) {
            console.log("No se pudo actualizar el usuario padre: " + idUsuario);
            return;
        }
        // Buscar el usuario para verificar
        const usuarioData = await obtenerDoc('usuarios', idUsuario);
        if (!usuarioData.data) {
            console.log("No se pudo actualizar el usuario padre: " + idUsuario);
            return;
        }
        const usuario = usuarioData.data;
        // Primero validar si tiene padre
        let idPadre = usuario.padre;
        if (idPadre) {
            console.log(idPadre);
            // Buscar el usuario padre
            const usuarioPadre = await obtenerDoc('usuarios', idPadre);
            if (!usuarioPadre.data) {
                console.log("No se pudo actualizar el usuario padre: " + idPadre);
                return;
            }
            console.log(usuarioPadre.data);
            let padre = usuarioPadre.data;
            let lado = "derecho";
            if (padre.directo_izquierdo === idUsuario) {
                lado = "izquierdo";
            }
            // console.log("LADO: ", lado);
            await agregarPuntosAncestros(usuario, valor, lado, idUsuario);
        } else {
            console.log("No tiene padre");
            return;
        }
    }

    const comisionDiezPorciento = async (padre, lado, valor, usuario) => {
        // Actualizar el saldo del padre con la comision del 10%
        let nuevaComision = valor / 10;
        // if (!padre.comisiones) {
        //     padre.comisiones = nuevaComision;
        // } else {
        //     padre.comisiones += nuevaComision;
        // }
        console.log("Comision: ", padre.comisiones);
        // Agregar al saldo del padre ************** o bonif.. 
        let nuevoSaldo = 0;
        if (padre.saldo) {
            nuevoSaldo = parseFloat(padre.saldo)
        }
        nuevoSaldo += nuevaComision;
        padre.saldo = nuevoSaldo;
        if (!padre.bonificaciones) {
            padre.bonificaciones = 0;
        }
        padre.bonificaciones += nuevaComision;

        // Actualizar el documento del padre en Firebase
        await actualizarDoc('usuarios', padre.id, padre);
        console.log(padre);
        // Agregar la comision a la BD en la coleccion de comisiones
        let descripcion = "Comisión del 10% por la compra de acciones (" + valor + ") de un directo (" + lado + "): " + usuario.nombre + " - " + usuario.correo;
        await agregarComision(padre, (valor / 10), descripcion, usuario);
    }

    // Funcion para agregar la comision a la BD en firebase
    const agregarComision = async (padre, cantidad, descripcion, usuario = null) => {
        // Se hace la comision al padre si se recibe un usuario es porque un hijo directo hizo la compra
        // Sino es porque el padre hizo choque de puntos
        let usuario_comprador = ''
        if (usuario) {
            usuario_comprador = usuario.id
        }
        let idComision = "COM" + new Date().getTime();
        let datos = {
            id: idComision,
            idUsuario: padre.id,
            cantidad: cantidad.toFixed(2),
            descripcion,
            usuario_comprador: usuario_comprador,
            fecha: new Date()
        }
        // Agregarla a firebase
        await guardarDoc('comisiones', datos);
        console.log("Datos: ", datos);
    }

    // Funcion para agregar puntos a los ancestros de un usuario validando que tengan ambos directos
    // Ademas se debe verificar que pertenezcan a la misma rama
    const agregarPuntosAncestros = async (usuario, cantidad, lado, idUsuarioCompra) => {
        // Se está recibiendo a un usuario de la rama izquierda o derecha
        // Validar que tenga padre al que se le va a agregar puntos
        // Se genera puntos al padre solo si tiene ambos directos activos
        // Para los puntos se debe validar que pertenezcan a la misma rama
        // Asi se suma a puntos_izquierdo o puntos_derecho
        let generarPuntos = true;
        console.log(usuario);
        if (!usuario.padre) {
            console.log("No tiene padre");
            return;
        }
        // Buscar el usuario padre
        const padreDoc = await obtenerDoc('usuarios', usuario.padre);
        console.log(`PADRE:`);
        console.log(padreDoc);
        if (padreDoc.data) {
            const padre = padreDoc.data;
            let puntos_derecha = 0;
            if (padre.puntos_derecho) {
                puntos_derecha = padre.puntos_derecho;
            }
            let puntos_izquierda = 0;
            if (padre.puntos_izquierdo) {
                puntos_izquierda = padre.puntos_izquierdo;
            }
            const usuarioCompraRes = await obtenerDoc('usuarios', idUsuarioCompra);
            const usuarioCompra = usuarioCompraRes.data;
            if (lado === 'derecho') {
                console.log("El lado es derecho");
                //////////////////////////////////////////////////////////////////////
                // Si esta en la lista de directos derechos se agrega la comision de 10%
                if (padre.directos_derechos && padre.directos_derechos.includes(idUsuarioCompra)) {
                    console.log("El usuario compra pertenece a la lista de directos derechos");
                    await comisionDiezPorciento(padre, lado, cantidad, usuarioCompra);
                    // Ver lo del directo_derecho_activo
                    if (!padre.directo_derecho_activo) {
                        console.log("El usuario padre no tenía directo derecho activo");
                        padre.directo_derecho_activo = idUsuarioCompra;
                        await actualizarDoc('usuarios', padre.id, padre);
                        // No se hacen puntos al ser el primero 
                        generarPuntos = false;
                    }
                } else {
                    console.log("El usuario compra no pertenece a la lista de directos derechos");
                }
                // Aqui ver lo de los puntos
                if (padre.directo_derecho === usuario.id) { // Verificar que este en la rama correcta
                    if (generarPuntos && padre.directo_derecho_activo !== idUsuarioCompra) {
                        console.log("No es el directo derecho activo"); // Al no ser el directo derecho activo se agregan puntos
                        // Verificar si el usuario padre tiene ambos directos activos
                        if (padre.directo_derecho_activo && padre.directo_izquierdo_activo) {
                            console.log("Tiene ambos directos activos");
                            // Se debe verificar que no supere los 100000 puntos que es el limite
                            // Si la cantidad es mayor a 100000, se queda con esos 100000
                            // Pero se debe tener en cuenta ambos lados que no supere los 100000
                            if (!padre.puntos_derecho) {
                                // Si la cantidad es mayor a 100000, se queda con esos 100000
                                if ((puntos_izquierda + cantidad) > 100000) {
                                    padre.puntos_derecho = 100000 - puntos_izquierda;
                                    padre.puntos_acum_derecho = 100000 - puntos_izquierda;
                                } else {
                                    padre.puntos_derecho = cantidad;
                                    padre.puntos_acum_derecho = cantidad;
                                }
                            } else {
                                // Si la cantidad es mayor a 100000, se queda con esos 100000
                                if ((puntos_izquierda + puntos_derecha + cantidad) > 100000) {
                                    padre.puntos_derecho = 100000 - (puntos_izquierda);
                                    padre.puntos_acum_derecho = 100000 - (puntos_izquierda);
                                } else {
                                    padre.puntos_derecho += cantidad;
                                    padre.puntos_acum_derecho += cantidad;
                                }
                            }

                            // Actualizar el documento del padre en Firebase
                            await updateDoc(doc(fire, "usuarios", padre.id), {
                                puntos_derecho: padre.puntos_derecho,
                                puntos_acum_derecho: padre.puntos_acum_derecho
                            });
                            console.log(`${padre.id} Pts_der: ${padre.puntos_derecho}`);
                            // Se podria agregar una notificacion a la BD
                            // Choque de puntos
                            let puntosMenor = Math.min(padre.puntos_izquierdo, padre.puntos_derecho);
                            console.log(`El menor de ${padre.puntos_izquierdo} y ${padre.puntos_derecho} es ${puntosMenor}`);
                            // Si el menor es mayor a cero se hace el choque de puntos
                            if (puntosMenor > 0) {
                                await choquePuntos(padre, puntosMenor);
                            }
                            else {
                                console.log("No se puede hacer el choque de puntos");
                            }
                        }
                    }
                    await agregarPuntosAncestros(padre, cantidad, lado, idUsuarioCompra);
                }
                ////////////////////////////////////////////////////////////////////

            } else if (lado === 'izquierdo') {
                console.log("El lado es izquierdo");
                //////////////////////////////////////////////////////////////////////
                // Si esta en la lista de directos izquierdos se agrega la comision de 10%
                if (padre.directos_izquierdos && padre.directos_izquierdos.includes(idUsuarioCompra)) {
                    console.log("El usuario compra pertenece a la lista de directos izquierdos");
                    await comisionDiezPorciento(padre, lado, cantidad, usuarioCompra);
                    // Ver lo del directo_izquierdo_activo
                    if (!padre.directo_izquierdo_activo) {
                        console.log("El usuario padre no tenia directo izquierdo activo");
                        padre.directo_izquierdo_activo = idUsuarioCompra;
                        await actualizarDoc('usuarios', padre.id, padre);
                        // No se hacen puntos al ser el primero 
                        generarPuntos = false;

                    }
                } else {
                    console.log("El usuario compra no pertenece a la lista de directos izquierdos");
                }

                // Aqui ver lo de los puntos
                if (padre.directo_izquierdo === usuario.id) { // Verificar que este en la rama correcta
                    console.log("El usuario es izquierdo");
                    if (generarPuntos && padre.directo_izquierdo_activo !== idUsuarioCompra) {
                        console.log("No es el directo izquierdo activo"); // Al no ser el directo izquierdo activo se agregan puntos
                        // Verificar si el usuario padre tiene ambos directos activos
                        if (padre.directo_derecho_activo && padre.directo_izquierdo_activo) {
                            console.log("Tiene ambos directos activos");
                            // Se debe verificar que no supere los 100000 puntos que es el limite
                            // Si la cantidad es mayor a 100000, se queda con esos 100000
                            // Pero se debe tener en cuenta ambos lados que no supere los 100000
                            if (!padre.puntos_izquierdo) {
                                // Si la cantidad es mayor a 100000, se queda con esos 100000
                                if ((puntos_derecha + cantidad) > 100000) {
                                    padre.puntos_izquierdo = 100000 - puntos_derecha;
                                    padre.puntos_acum_izquierdo = 100000 - puntos_derecha;
                                } else {
                                    padre.puntos_izquierdo = cantidad;
                                    padre.puntos_acum_izquierdo = cantidad;
                                }
                            } else {
                                // Si la cantidad es mayor a 100000, se queda con esos 100000
                                if ((puntos_derecha + puntos_izquierda + cantidad) > 100000) {
                                    padre.puntos_izquierdo = 100000 - puntos_derecha;
                                    padre.puntos_acum_izquierdo = 100000 - puntos_derecha;
                                } else {
                                    padre.puntos_izquierdo += cantidad;
                                    padre.puntos_acum_izquierdo += cantidad;
                                }
                            }
                            // Actualizar el documento del padre en Firebase
                            await updateDoc(doc(fire, "usuarios", padre.id), {
                                puntos_izquierdo: padre.puntos_izquierdo,
                                puntos_acum_izquierdo: padre.puntos_acum_izquierdo
                            });
                            console.log(`${padre.id} Pts_izq: ${padre.puntos_izquierdo}`);
                            // Se podria agregar una notificacion a la BD
                            // Choque de puntos
                            let puntosMenor = Math.min(padre.puntos_izquierdo, padre.puntos_derecho);
                            console.log(`El menor de ${padre.puntos_izquierdo} y ${padre.puntos_derecho} es ${puntosMenor}`);
                            // Si el menor es mayor a cero se hace el choque de puntos
                            if (puntosMenor > 0) {
                                await choquePuntos(padre, puntosMenor);
                            } else {
                                console.log("No hay choque de puntos");
                            }
                        } else {
                            console.log("No tiene ambos directos activos");
                        }
                    } else {
                        console.log("No es el directo izquierdo activo o no genera puntos");
                    }
                    await agregarPuntosAncestros(padre, cantidad, lado, idUsuarioCompra);
                } else {
                    console.log(`${padre.directo_izquierdo}->El usuario ${usuario.id} no es izquierdo del padre ${padre.id}`);
                }
                ////////////////////////////////////////////////////////////////////
            }
        }
    }

    const choquePuntos = async (padre, puntosMenor) => {
        let comision = puntosMenor * 0.07;
        console.log(`La comision es ${comision}`);
        // Agregar comision al usuario que hizo el choque de puntos
        // if (!padre.comisiones) {
        //     padre.comisiones = comision;
        // } else {
        //     padre.comisiones += comision;
        // }
        // Agregar la comision a la BD en la coleccion de comisiones
        let descripcion = `Comisión del 7% por choque de puntos. Puntos del equipo más débil: ${puntosMenor}`;
        await agregarComision(padre, comision, descripcion);
        console.log(`Comisiones de ${padre.id}: ${padre.comisiones}`);
        // Restar el menor de los puntos_izquierdo y puntos_derecho
        if (padre.puntos_izquierdo - puntosMenor < 0) {
            padre.puntos_izquierdo = 0;
        } else {
            padre.puntos_izquierdo -= puntosMenor;
        }
        if (padre.puntos_derecho - puntosMenor < 0) {
            padre.puntos_derecho = 0;
        } else {
            padre.puntos_derecho -= puntosMenor;
        }
        padre.puntos_izquierdo_restantes = padre.puntos_izquierdo;
        padre.puntos_derecho_restantes = padre.puntos_derecho;

        console.log(padre);
        // Actualizar su saldo y comisiones
        if (!padre.saldo) {
            padre.saldo = 0;
        }
        padre.saldo += comision;
        if (!padre.bonificaciones) {
            padre.bonificaciones = 0;
        }
        padre.bonificaciones += comision;
        // Actualizar el usuario en la BD
        await actualizarDoc('usuarios', padre.id, padre);
    }

    const eliminarCompra = (obj) => {
        borrarDoc('compraAcciones', obj.id).then((dox) => {
            if (dox.res) {
                devolverDineroSaldo(obj)
            }
        })
    }

    const devolverDineroSaldo = (obj) => {
        let idUsuario = obj.idUsuario
        let valor = obj.valor + (obj.valor * 0.05) + 1

        obtenerDoc('usuarios', idUsuario).then((dox) => {
            if (dox.res) {
                let usuario = dox.data
                let saldoActual = usuario.saldo ? usuario.saldo : 0
                let saldoNuevo = parseInt(saldoActual) + valor
                // let saldoNuevo = parseFloat(saldoActual) + valor
                actualizarUsuario(obj, saldoNuevo)
            }
        })
    }

    const actualizarUsuario = (obj, saldoNuevo = 0) => {

        try {
            let idUsuario = obj.idUsuario
            contarPropiedadSumaWheres({
                coleccion: 'compraAcciones', propiedad: 'valor',
                wheres: [where('idUsuario', '==', idUsuario), where('estado', '==', 'Activa')]
            }).then((dox) => {
                if (dox.res) {
                    let accionesActivas = dox.data
                    let datos = {
                        acciones: accionesActivas
                    }
                    if (saldoNuevo > 0) {
                        datos.saldo = saldoNuevo
                    }
                    actualizarDoc('usuarios', idUsuario, datos).then((des) => {
                        if (des.res) {
                            actualizarTransaccion(obj)
                        }
                    })
                } else {
                    cerrarCargador()
                    // alert('Error al subir datos, por favor intentelo nuevamente')
                }
            })
        } catch (e) {
            console.log(e)
        }
    }

    const actualizarTransaccion = (obj) => {
        actualizarDoc('transacciones', obj.idTransaccion, { estado: obj.estado }).then((des) => {
            if (des.res) {
                cerrar()
                cerrarCargador()
                alert('Cambios guardados con exito')
                realizado()
            } else {
                cerrarCargador()
                alert('Error al subir datos, por favor intentelo nuevamente')
            }
        })
    }



    return (

        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
        >
            <Cargador />

            <Grid item container lg={12} sm={12} xs={12} sx={{ justifyContent: 'center' }}>
                <Formulario_Compra_Acciones props={props} estado={datos.estado && datos.estado !== 'Aprobado'} />
            </Grid>

            {datos.estado && datos.estado !== 'Aprobado' &&
                <Grid item container lg={12} sm={12} xs={12} sx={{ justifyContent: 'center', marginTop: 2 }}>
                    <Button
                        onClick={() => guardarCambios()}
                        startIcon={<TaskAlt />}
                    >
                        Guardar Cambios
                    </Button>
                </Grid>
            }
        </Grid>
    )
}
export default Dialogo_Compra_Acciones