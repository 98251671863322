import React, { useState, useEffect } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    Button,
    Select,
    MenuItem,
    FormControl, InputLabel, Box, Typography, CircularProgress
} from '@mui/material';
import { collection, doc, getDocs, query, updateDoc, where } from 'firebase/firestore';
import { fire } from '../../fire';
import { useLoaders } from '../../Modulo_Loaders/Hooks/useLoaders';
import logo from '../../Recursos/logo_blanco.svg'
import { guardarDoc } from "../../Servicios/BD/guardarDoc";
import { actualizarDoc } from "../../Servicios/BD/actualizarDoc";
import { obtenerDoc } from "../../Servicios/BD/obtenerDoc";

const DialogoSeleccion = ({ open, onClose, nombre_tramo, idUsuario, idUsuarioMasAncestro }) => {
    // Buscar en la base de datos de firebase los usuarios que no tengan un padre
    const [usuarios, setUsuarios] = useState([]);
    const [IDusuarioSeleccionado, setIDUsuarioSeleccionado] = useState('');
    const [usuarioSeleccionado, setUsuarioSeleccionado] = useState(null);
    const { Cargador, abrirCargador, cerrarCargador } = useLoaders({
        logo: logo,
    })
    const [usuarioActual, setUsuarioActual] = useState(null);
    const [isLoading, setIsLoading] = useState(true); // Estado para controlar la carga


    useEffect(() => {
        const obtenerUsuarioActual = async () => {
            const usuario = await obtenerDoc('usuarios', idUsuario);
            setUsuarioActual(usuario.data);
        };

        const obtenerUsuariosSinPadre = async () => {
            setIsLoading(true); // Inicia la carga
            const usuariosRef = collection(fire, 'usuarios');
            const querySnapshot = await getDocs(usuariosRef);

            const usuariosSinPadre = querySnapshot.docs
                .filter(doc => {
                    const data = doc.data();
                    return (
                        !data.padre &&
                        data.tipo !== 'admin' &&
                        data.id !== idUsuario &&
                        data.id !== idUsuarioMasAncestro
                    );
                })
                .map(doc => ({
                    id: doc.id,
                    ...doc.data()
                }));

            setUsuarios(usuariosSinPadre);
            setIsLoading(false); // Finaliza la carga
        };

        if (open) {
            obtenerUsuarioActual().then(() => {
                obtenerUsuariosSinPadre();
            });
        }
    }, [open, idUsuario, idUsuarioMasAncestro]);


    // console.log(usuarios);

    const handleGuardar = async () => {

        if (!IDusuarioSeleccionado) {
            console.log("No se selecciono ningun usuario");
            return;
        }

        const usuario = await obtenerDoc('usuarios', idUsuario);
        if (!usuario.data) {
            console.log("No se pudo obtener el usuario hijo: " + IDusuarioSeleccionado);
            return;
        }

        const usuarioHijo = await obtenerDoc('usuarios', IDusuarioSeleccionado);
        if (!usuarioHijo.data) {
            console.log("No se pudo obtener el usuario hijo: " + IDusuarioSeleccionado);
            return;
        }
        // Aquí realizar la asignación al padre
        // con el idUsuario que se pasa como prop
        console.log('ID Usuario actual:', idUsuario);
        console.log("Tramo", nombre_tramo);
        console.log("Usuario data", usuario.data);
        if (!usuarioActual) {
            console.log("No se pudo actualizar el usuario padre: " + idUsuario);
            alert("No se pudo actualizar el usuario");
            //cerrarCargador();
            return;
        }

        console.log("Usuario actual: ", usuarioActual);
        console.log("Usuario hijo ahora: ", usuarioHijo.data);
        // Se va  a agregar al hijo el padre
        // usuarioHijo.data.referencia = idUsuario;
        // Actualizar el documento del hijo en Firebase
        abrirCargador("Actualizando...");

        await updateDoc(doc(fire, "usuarios", usuarioHijo.data.id), {
            referencia: idUsuario,
        })


        await agregarDirecto(usuario.data, usuarioHijo.data, nombre_tramo);
        await actualizarUsuarioPadre(usuario.data, usuarioHijo.data, nombre_tramo);

        cerrarCargador()
        alert("Se han realizado los cambios");
        onClose();
        // Recargar la pagina
        window.location.reload();
    };

    const agregarDirecto = async (padre, usuarioHijo, lado) => {
        let idHijo = usuarioHijo.id
        if (lado === 'izquierdo') {
            // Guardar el id del hijo en directos_izquierdos
            if (!padre.directos_izquierdos) {
                padre.directos_izquierdos = [];
            }
            padre.directos_izquierdos.push(idHijo);
            // Si el usuario no tiene directo_izquierdo_activo
            if (!padre.directo_izquierdo_activo) {
                // Si el usuarioHijo tiene acciones
                if (usuarioHijo.acciones) {
                    // Se asigna el usuarioHijo como directo_izquierdo_activo
                    padre.directo_izquierdo_activo = idHijo;
                    // Actualizar el documento del padre en Firebase
                    await updateDoc(doc(fire, "usuarios", padre.id), {
                        directo_izquierdo_activo: padre.directo_izquierdo_activo,
                    });
                }
            }

            // Actualizar el documento del padre en Firebase
            await updateDoc(doc(fire, "usuarios", padre.id), {
                directos_izquierdos: padre.directos_izquierdos,
            });
        } else {
            // Guardar el id del hijo en directos_derechos
            if (!padre.directos_derechos) {
                padre.directos_derechos = [];
            }
            padre.directos_derechos.push(idHijo);
            // Si el usuario no tiene directo_derecho_activo
            if (!padre.directo_derecho_activo) {
                // Si el usuarioHijo tiene acciones
                if (usuarioHijo.acciones) {
                    // Se asigna el usuarioHijo como directo_derecho_activo
                    padre.directo_derecho_activo = idHijo;
                    // Actualizar el documento del padre en Firebase
                    await updateDoc(doc(fire, "usuarios", padre.id), {
                        directo_derecho_activo: padre.directo_derecho_activo,
                    });
                }
            }
            // Actualizar el documento del padre en Firebase
            await updateDoc(doc(fire, "usuarios", padre.id), {
                directos_derechos: padre.directos_derechos,
            })
        }
        console.log("usuario padre", padre);
    }

    const actualizarUsuarioPadre = async (usuario, usuarioHijo, lado) => {

        console.log(usuario);
        console.log(usuarioHijo);

        if (!usuario) {
            console.log("Usuario padre no encontrado.");
            // alert("No se pudo actualizar el usuario porque no se encontró");
            //cerrarCargador();
            return;
        }
        if (!usuarioHijo) {
            console.log("Usuario hijo no encontrado.");
            // alert("No se pudo actualizar el usuario porque no se encontró el usuario a asignar");
            //cerrarCargador();
            return;
        }

        console.log("Se va a actualizar el usuario padre");
        if (lado === 'izquierdo') {
            console.log("El lado es izquierdo");
            let idUsuarioIzq = usuario.directo_izquierdo;
            if (!idUsuarioIzq) {
                console.log("El usuario padre no tiene usuario asignado a su lado izquierdo");
                // Si no hay un hijo directo a su izquierda, asignarlo a su izquierda
                usuario.directo_izquierdo = usuarioHijo.id;
                // Asignarle como padre al hijo que tiene el idHijo
                usuarioHijo.padre = usuario.id;

                // Actualizar el documento del usuario en Firebase
                await updateDoc(doc(fire, "usuarios", usuario.id), {
                    directo_izquierdo: usuarioHijo.id,
                });
                // Actualizar el documento del hijo en Firebase
                await updateDoc(doc(fire, "usuarios", usuarioHijo.id), {
                    padre: usuario.id,
                });
            } else {
                // Si ya tiene un hijo directo a su izquierda, se debe buscar recursivamente
                // Buscar el hijo directo a la izquierda del usuarioHijo
                let hijoIzq = await obtenerDoc('usuarios', idUsuarioIzq);
                if (!hijoIzq.data) {
                    console.log("No se pudo obtener el usuario hijo directo: " + idUsuarioIzq);
                    // alert("No se pudo obtener el usuario hijo directo");
                    //cerrarCargador();
                    return;
                }
                console.log("El usuario hijo directo es: ", hijoIzq.data);
                await actualizarUsuarioPadre(hijoIzq.data, usuarioHijo, lado);
            }

        } else {
            // Lado derecho
            console.log("El lado es derecho");
            let idUsuarioDer = usuario.directo_derecho;
            if (!idUsuarioDer) {
                console.log("El usuario padre no tiene usuario asignado a su lado derecho");
                // Si no hay un hijo directo a su derecha, asignarlo a su derecha
                usuario.directo_derecho = usuarioHijo.id;
                // Asignarle como padre al hijo que tiene el idHijo
                usuarioHijo.padre = usuario.id;

                // Actualizar el documento del usuario en Firebase
                await updateDoc(doc(fire, "usuarios", usuario.id), {
                    directo_derecho: usuarioHijo.id,
                });
                // Actualizar el documento del hijo en Firebase
                await updateDoc(doc(fire, "usuarios", usuarioHijo.id), {
                    padre: usuario.id,
                });

            } else {
                // Si ya tiene un hijo directo a su derecha, se debe buscar recursivamente
                // Buscar el hijo directo a la derecha del usuarioHijo
                let hijoDer = await obtenerDoc('usuarios', idUsuarioDer);
                if (!hijoDer.data) {
                    console.log("No se pudo obtener el usuario hijo directo: " + idUsuarioDer);
                    // alert("No se pudo obtener el usuario hijo directo");
                    //cerrarCargador();
                    return;
                }

                console.log("El usuario hijo directo es: ", hijoDer.data);
                await actualizarUsuarioPadre(hijoDer.data, usuarioHijo, lado);

            }

        }
    }

    return (
        <Dialog open={open} onClose={onClose} >
            <Cargador />

            <DialogTitle>{nombre_tramo === 'izquierdo' ? 'Agregar Izquierdo' : 'Agregar Derecho'}</DialogTitle>
            <DialogContent sx={{ width: '350px' }}>
                {isLoading ? ( // Muestra el indicador de carga mientras se está cargando
                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '150px' }}>
                        <CircularProgress />
                    </Box>
                ) : (

                    <Box sx={{ mt: 2, mb: 3 }}>
                        <FormControl fullWidth>
                            <InputLabel id="usuario-select-label">Seleccionar Usuario</InputLabel>
                            <Select
                                labelId="usuario-select-label"
                                value={IDusuarioSeleccionado}
                                label="Seleccionar Usuario"
                                onChange={(e) => setIDUsuarioSeleccionado(e.target.value)}
                            >
                                {usuarios.map(usuario => (
                                    <MenuItem key={usuario.id} value={usuario.id}>
                                        <Typography>
                                            {usuario.nombre} - {usuario.correo}
                                        </Typography>
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Box>
                )}
                {/* Botones con espaciado horizontal */}

                <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 2 }}>
                    <Button onClick={onClose} color="primary" variant="outlined">
                        Cerrar
                    </Button>
                    <Button disabled={isLoading || !IDusuarioSeleccionado} variant="contained" color="primary" onClick={handleGuardar}>
                        Guardar
                    </Button>
                </Box>
            </DialogContent>
        </Dialog>
    );
};

export default DialogoSeleccion;