/**************************************************
 * Nombre:       Seccion_Categorias
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     12 Hr
 **************************************************/
import { Grid, useMediaQuery, Typography, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { useDialogo } from "../../Modulo_dialogos/Hooks/useDialogo";
import TituloNotificaciones from "../../Modulo_dashboard/ComponentesSecundarios/TituloNotificaciones";
import TablaBasica from "../../Modulo_tablas/Componentes/TablaBasica";
import { theme } from "../../Tema";
import { ManageSearch } from "@mui/icons-material";
import { contarColeccionWhere } from "../../Servicios/BD/Contadores/contarColeccionWhere";
import { where } from "firebase/firestore";
import { useObtenerColleccionTablaWhere } from "../../Servicios/BD/useObtenerColleccionTablaWhere";
import Dialogo_Usuario from "../Dialogos/Dialogo_Usuario";
import { collection, getDocs } from "firebase/firestore";
import { fire } from "../../fire";


const Seccion_Usuarios = () => {
    const sCell = useMediaQuery(theme.breakpoints.only('xs'))
    const [numUsuarios, setNumUsuarios] = useState(0)
    const [recargar, setRecargar] = useState(false)
    const [wheres, setWheres] = useState([where('tipo', '!=', 'admin')])
    const { props, cargarNuevamente } = useObtenerColleccionTablaWhere({
        coleccion: 'usuarios',
        filtroInicial: 'fecha',
        dirInicial: 'desc',
        wheres: wheres
    })
    const { Dialogo, abrir } = useDialogo({
        Componente: Dialogo_Usuario,
        realizado: () => setRecargar(!recargar),
        titulo: 'Detalle de Usuario',
        grande: false,
    })

    const manejardorClicks = (row, id) => {

        switch (id) {
            case 'ver':
                abrir(row)
                break
        }

    }


    const [todosUsuarios, setTodosUsuarios] = useState([]);
    const [busqueda, setBusqueda] = useState('');
    const [usuariosFiltrados, setUsuariosFiltrados] = useState([]);
    const [hayFiltrados, setHayFiltrados] = useState(true);


    useEffect(() => {
        const obtenerTodosUsuarios = async () => {
            const usuariosRef = collection(fire, 'usuarios');
            const querySnapshot = await getDocs(usuariosRef);
            const usuarios = querySnapshot.docs.map(doc => doc.data());
            setTodosUsuarios(usuarios);
        };

        obtenerTodosUsuarios();
    }, []);

    // useEffect(() => {
    //     setTablaActualizada(!tablaActualizada);
    // }, [usuariosFiltrados]);


    const manejadorBusqueda = (e) => {
        setBusqueda(e.target.value);
        if (e.target.value === '') {
            // console.log('busqueda vacia');
            setUsuariosFiltrados([]);
        } else {
            const usuariosFiltrados = todosUsuarios.filter(usuario => {
                return usuario.correo.toLowerCase().includes(e.target.value.toLowerCase());
            });
            setUsuariosFiltrados(usuariosFiltrados);
            if (usuariosFiltrados.length === 0) {
                setHayFiltrados(false)
            } else {
                setHayFiltrados(true)
            }
            // console.log(usuariosFiltrados);
            // props.data = usuariosFiltrados
            // console.log(props.data);
            // cargarNuevamente()
        }
    };


    const botones = []

    const [totalComisiones, setTotalComisiones] = useState(0);
    const [totalRendimientos, setTotalRendimientos] = useState(0);
    const [totalSuma, setTotalSuma] = useState(0);

    useEffect(() => {
        const obtenerTotales = async () => {
            const usuariosRef = collection(fire, 'usuarios');
            const querySnapshot = await getDocs(usuariosRef);

            let totalComisiones = 0;
            let totalRendimientos = 0;

            querySnapshot.forEach(doc => {
                const data = doc.data();
                totalComisiones += data.bonificaciones || 0;
                totalRendimientos += data.rendimientos || 0;
            });

            setTotalComisiones(totalComisiones);
            setTotalRendimientos(totalRendimientos);
            setTotalSuma(totalComisiones + totalRendimientos);
        };

        obtenerTotales();
    }, []);


    useEffect(() => {
        contarColeccionWhere({ coleccion: 'usuarios', wheres: [where('tipo', '!=', 'admin')] }).then((dox) => {
            if (dox.res) {
                setNumUsuarios(dox.data)
            }
        })

        cargarNuevamente()

    }, [recargar]);

    return (
        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
        >

            <Dialogo />

            <Grid item container lg={12} sm={12} xs={12} sx={{ justifyContent: 'flex-start' }}>
                <TituloNotificaciones titulo={'Usuarios'} textoComplementario={'en lista'}
                    textoResaltado={`${numUsuarios} Usuarios`} botones={botones} />
            </Grid>

            {/* Mostrar informacion acerca del total de comisiones de todos los usuarios */}
            <Grid item container lg={12} sm={12} xs={12} sx={{ justifyContent: 'flex-start', padding: 2, backgroundColor: '#f7f7f7', borderRadius: 2, textAlign: 'center' }}>

                <Grid container spacing={2}>
                    <Grid item xs={12} sm={4} lg={4}>
                        <Typography variant="body1" sx={{ fontSize: 18, fontWeight: 'bold' }}>Suma Comisiones:</Typography>
                        <Typography variant="body1" sx={{ fontSize: 24, color: '#001579' }}>${totalComisiones.toFixed(2)}</Typography>
                    </Grid>
                    <Grid item xs={12} sm={4} lg={4}>
                        <Typography variant="body1" sx={{ fontSize: 18, fontWeight: 'bold' }}>Suma Rendimientos:</Typography>
                        <Typography variant="body1" sx={{ fontSize: 24, color: '#65bd00' }}>${totalRendimientos.toFixed(2)}</Typography>
                    </Grid>
                    <Grid item xs={12} sm={4} lg={4}>
                        <Typography variant="body1" sx={{ fontSize: 18, fontWeight: 'bold' }}>Total:</Typography>
                        <Typography variant="body1" sx={{ fontSize: 24, color: '#dd8400' }}>${totalSuma.toFixed(2)}</Typography>
                    </Grid>
                </Grid>
            </Grid>


            <Grid item container lg={12} sm={12} xs={12} sx={{ justifyContent: 'flex-start', marginTop: 5 }}>
                {/* Buscador */}
                <Grid item container lg={12} sm={12} xs={12} sx={{ justifyContent: 'flex-start', padding: 1, backgroundColor: '#f7f7f7', borderRadius: 2, textAlign: 'center' }}>
                    <Typography variant="h6">Buscar por correo:</Typography>
                    <TextField
                        label="Ingrese el correo..."
                        value={busqueda}
                        onChange={manejadorBusqueda}
                        sx={{ width: '100%' }}
                    />
                </Grid>
                {/* Si hay datos se muestran sino muestra data */}
                {!hayFiltrados && <Typography sx={{ fontSize: 14, fontWeight: 400, fontFamily: "Montserrat", color: 'red' }}>No se encontró ningúna coincidencia</Typography>}

                {hayFiltrados && usuariosFiltrados.length > 0 && <Typography sx={{ fontSize: 14, fontWeight: 400, fontFamily: "Montserrat", color: 'red' }}>Se encontró {usuariosFiltrados.length} coincidencias</Typography>}
                <TablaBasica
                    filtros={filtros}
                    datosCabecera={sCell ? datosCabeceraCell : datosCabecera}
                    datos={usuariosFiltrados}
                    {...props}
                    click={manejardorClicks}
                    botonesTabla={botonesTabla}
                    numeroFilas={numUsuarios}
                />
            </Grid>


        </Grid>
    )

}
export default Seccion_Usuarios

const filtros = [
    {
        nombre: 'Mas Reciente',
        propiedad: 'fecha',
        direccion: 'desc'

    },
    {
        nombre: 'Nombre',
        propiedad: 'nombre',

    },
    {
        nombre: 'Saldo',
        propiedad: 'saldo',
        direccion: 'desc'

    }
]

const datosCabecera = [
    {
        nombre: 'Nombre',
        direccion: 'left',
        propiedad: 'nombre',
        tipo: 'fechaDato'

    },
    {
        nombre: 'Correo',
        direccion: 'left',
        propiedad: 'correo',

    },
    {
        nombre: 'Saldo',
        direccion: 'left',
        propiedad: 'saldo',
        tipo: 'moneda'

    },
    {
        nombre: 'Acciones',
        direccion: 'right',
        propiedad: 'acciones',
        tipo: 'moneda'

    }


]


const datosCabeceraCell = [
    {
        nombre: 'Fecha',
        direccion: 'left',
        propiedad: 'fecha',
        tipo: 'fecha'

    },
    {
        nombre: 'Nombre',
        direccion: 'right',
        propiedad: 'nombre',

    }
]

const botonesTabla = [
    {
        nombre: 'Ver',
        icono: ManageSearch,
        id: 'ver'
    },

]



