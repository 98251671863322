/**************************************************
 * Nombre:       Formulario_Productos
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import { Button, Divider, Grid, Typography } from "@mui/material";
import IngresoTexto from "../../Modulo_formularios/Componentes/IngresoTexto";
import {
    AccountCircle,
    AccountTree,
    ArrowBack,
    ArrowForward,
    Badge,
    BarChart,
    Mail,
    Money,
    Moving,
    PhoneAndroid,
    PriceCheck,
    Wallet
} from "@mui/icons-material";
import IngresoDinero from "../../Modulo_formularios/Componentes/IngresoDinero";
import { useEffect, useState } from "react";
import DialogoUsuarios from "../Dialogos/DialogoUsuarios";
import DialogoSeleccion from "../Dialogos/DialogoSeleccion";
import { obtenerDoc } from "../../Servicios/BD/obtenerDoc";

const Formulario_Usuario = ({ ...props }) => {

    // Para manejar el cuadro de dialogo
    const [openDialog, setOpenDialog] = useState(false);
    const [openDialogSeleccion, setOpenDialogSeleccion] = useState(false);
    const [tramoSeleccionado, setTramoSeleccionado] = useState([]);
    const [tramoActual, setTramoActual] = useState('izquierdo');
    const [tramoActualSeleccion, setTramoActualSeleccion] = useState('izquierdo');

    const [usuariosIzquierdos, setUsuariosIzquierdos] = useState([]);
    const [usuariosDerechos, setUsuariosDerechos] = useState([]);
    // Para guardar el id del usuario padre mas ancestro para que no se pueda seleccionar
    const [idUsuarioMasAncestro, setIdUsuarioMasAncestro] = useState("");

    // Función recursiva para obtener el usuario padre más ancestro
    const obtenerUsuarioMasAncestro = async (idUsuario) => {
        if (!idUsuario) return null; // No hay padre más ancestro si no hay usuario

        try {
            const respuesta = await obtenerDoc("usuarios", idUsuario);
            if (!respuesta.res) {
                console.error(`Error al obtener el usuario: ${respuesta.data}`);
                return null;
            }

            const usuario = respuesta.data;

            if (!usuario || !usuario.padre) {
                // Si el usuario no tiene padre, él mismo es el más ancestro
                return idUsuario;
            }

            // Llamada recursiva para seguir subiendo en la jerarquía
            return await obtenerUsuarioMasAncestro(usuario.padre);
        } catch (error) {
            console.error("Error al obtener el usuario más ancestro:", error);
            return null;
        }
    };

    // Función recursiva para obtener los usuarios de un tramo
    const obtenerUsuariosPorTramo = async (idUsuario, nombreTramo, idActual) => {
        if (!idUsuario) return []; // Si no hay ID, no hay datos que buscar

        try {
            // console.log(`Obteniendo datos para ${idUsuario} en el tramo ${nombreTramo}`);

            const respuesta = await obtenerDoc("usuarios", idUsuario);
            if (!respuesta.res) {
                // console.log(`Error al obtener el usuario: ${respuesta.data}`);
                return [];
            }

            const usuario = respuesta.data;

            if (!usuario) {
                // console.log(`Usuario no encontrado: ${idUsuario}`);
                return [];
            }

            // console.log(`Usuario encontrado: ${usuario.nombre}`);

            const siguienteTramo =
                nombreTramo === "izquierdo" ? usuario.directo_izquierdo : usuario.directo_derecho;

            // Llamada recursiva para obtener los usuarios en el tramo correspondiente
            const tramoUsuarios = await obtenerUsuariosPorTramo(siguienteTramo, nombreTramo, idActual);

            // Excluir el usuario actual (idActual)
            if (idUsuario === idActual) {
                return [...tramoUsuarios]; // No incluir el usuario actual
            }

            return [{ id: idUsuario, nombre: usuario.nombre }, ...tramoUsuarios];
        } catch (error) {
            // console.error(`Error obteniendo los usuarios del tramo ${nombreTramo}:`, error);
            return [];
        }
    };

    // Cargar usuarios izquierdos y derechos al montar el componente
    useEffect(() => {
        const cargarUsuarios = async () => {
            if (!props.datos?.id) return;

            // console.log("Cargando usuarios para el ID:", props.datos.id);

            try {
                const izquierdos = await obtenerUsuariosPorTramo(props.datos.id, "izquierdo", props.datos.id);
                const derechos = await obtenerUsuariosPorTramo(props.datos.id, "derecho", props.datos.id);

                // console.log("Usuarios Izquierdos:", izquierdos);
                // console.log("Usuarios Derechos:", derechos);
                const masAncestro = await obtenerUsuarioMasAncestro(props.datos.id);

                setUsuariosIzquierdos(izquierdos);
                setUsuariosDerechos(derechos);

                setIdUsuarioMasAncestro(masAncestro);
                console.log("ID del usuario mas ancestro:", masAncestro);
            } catch (error) {
                console.error("Error al cargar los usuarios:", error);
            }
        };

        cargarUsuarios();
    }, [props.datos?.id]);



    // Abrir cuadro de diálogo con la lista correspondiente
    const handleOpenDialog = (nombre) => {
        if (nombre === "izquierdo") {
            // setTramoSeleccionado(usuariosIzquierdos);
            setTramoSeleccionado(props.datos.directos_izquierdos);
        } else {
            // setTramoSeleccionado(usuariosDerechos);
            setTramoSeleccionado(props.datos.directos_derechos);
        }
        setTramoActual(nombre);
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    const handleCloseDialogSeleccion = () => {
        setOpenDialogSeleccion(false);
    };// Cerrar el cuadro de dialogo para seleccionar
    const handleOpenDialogSeleccion = (nombreTramo) => {
        setOpenDialogSeleccion(true);
        setTramoActualSeleccion(nombreTramo);
    };


    return (
        <form noValidate style={{ width: '100%' }}>
            <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                spacing={2}
                sx={{ p: 1 }}
            >


                <Grid item container lg={12} sm={12} xs={12} sx={{ justifyContent: 'center' }}>
                    <IngresoTexto Icono={AccountCircle} nombre={'Nombre'} dato={'nombre'}  {...props}

                    />
                </Grid>

                <Grid item container lg={12} sm={12} xs={12} sx={{ justifyContent: 'center' }}>
                    <IngresoTexto Icono={Mail} nombre={'Correo'} dato={'correo'} editable={false} {...props}

                    />
                </Grid>

                <Grid item container lg={6} sm={6} xs={6} sx={{ justifyContent: 'center' }}>
                    <IngresoTexto Icono={Badge} nombre={'Cc/Nit'} dato={'cc'}  {...props}

                    />
                </Grid>

                <Grid item container lg={6} sm={6} xs={6} sx={{ justifyContent: 'center' }}>
                    <IngresoTexto Icono={PhoneAndroid} nombre={'Numero de Contacto'} dato={'numero'}  {...props}
                    />
                </Grid>

                <Grid item container lg={12} sm={12} xs={12} sx={{ justifyContent: 'center' }}>
                    <Divider sx={{ width: '100%' }} />
                </Grid>

                <Grid item container lg={6} sm={6} xs={6} sx={{ justifyContent: 'center' }}>
                    <IngresoDinero Icono={Wallet} nombre={'Saldo'} dato={'saldo'}{...props} />
                </Grid>

                <Grid item container lg={6} sm={6} xs={6} sx={{ justifyContent: 'center' }}>
                    <IngresoDinero Icono={BarChart} nombre={'Acciones'} dato={'acciones'}{...props}
                        editable={false}
                    />
                </Grid>

                <Grid item container lg={6} sm={6} xs={6} sx={{ justifyContent: 'center' }}>
                    <IngresoDinero Icono={Moving} nombre={'Rendimientos'} dato={'rendimientos'}{...props} />
                </Grid>

                <Grid item container lg={6} sm={6} xs={6} sx={{ justifyContent: 'center' }}>
                    <IngresoDinero Icono={PriceCheck} nombre={'Bonificaciones'} dato={'bonificaciones'}{...props} />
                </Grid>

                {/* ////////////////////// Para mostrar informacion de binarios en cada usuario */}

                <Grid item container lg={12} sm={12} xs={12} sx={{ justifyContent: 'center' }}>
                    <Divider sx={{ width: '100%' }} />
                </Grid>
                <Grid item container lg={12} sm={12} xs={12} sx={{ justifyContent: 'center' }}>
                    <Typography>Información Binarios</Typography>

                </Grid>

                <Grid item container lg={12} sm={12} xs={12} sx={{ justifyContent: 'center' }}>
                    <IngresoTexto Icono={AccountTree} nombre={'Usuario padre'} dato={'referencia'} editable={false} {...props}
                    />
                </Grid>

                <Grid item container lg={6} sm={6} xs={6} sx={{ justifyContent: 'center' }}>
                    <IngresoDinero Icono={Wallet} nombre={'Puntos Disponible Izq.'} dato={'puntos_izquierdo'}{...props} />
                </Grid>
                <Grid item container lg={6} sm={6} xs={6} sx={{ justifyContent: 'center' }}>
                    <IngresoDinero Icono={Wallet} nombre={'Puntos Disponible Der.'} dato={'puntos_derecho'}{...props} />
                </Grid>
                {/* <Grid item container lg={6} sm={6} xs={6} sx={{ justifyContent: 'center' }}>
                    <IngresoDinero Icono={Wallet} nombre={'Total Acciones Izq.'} dato={'total_acciones_izquierda'}{...props} />
                </Grid>
                <Grid item container lg={6} sm={6} xs={6} sx={{ justifyContent: 'center' }}>
                    <IngresoDinero Icono={Wallet} nombre={'Total Acciones Der.'} dato={'total_acciones_derecha'}{...props} />
                </Grid> */}

                <Grid item container lg={6} sm={6} xs={6} sx={{ justifyContent: 'center' }}>
                    {/* Mostrar un boton que al presionar me salga un cuadro de dialogo con una lista de los usuarios en tramo izquierdo */}
                    <Button
                        variant="contained"
                        color="success"
                        // size="small"
                        sx={{ border: '2px solid black', width: '100%', padding: '5px' }}
                        onClick={() => {
                            handleOpenDialog('izquierdo')
                        }}
                    >
                        <ArrowBack></ArrowBack>
                        Izquierdos</Button>
                </Grid>
                <Grid item container lg={6} sm={6} xs={6} sx={{ justifyContent: 'center' }}>
                    {/* Mostrar un boton que al presionar me salga un cuadro de dialogo con una lista de los usuarios en tramo izquierdo */}
                    <Button
                        variant="contained"
                        color="success"
                        // size="small"
                        sx={{ border: '2px solid black', width: '100%', padding: '5px' }}
                        onClick={() => {
                            handleOpenDialog('derecho')
                        }}
                    > Derechos<ArrowForward></ArrowForward></Button>
                </Grid>

                <Grid item container lg={6} sm={6} xs={6} sx={{ justifyContent: 'center' }}>
                    {/* Mostrar un boton que al presionar me salga un cuadro de dialogo con una lista de los usuarios que se podrian asignar */}
                    <Button
                        variant="contained"
                        color="success"
                        // size="small"
                        sx={{ border: '2px solid black', width: '100%', padding: '5px' }}
                        onClick={() => {
                            handleOpenDialogSeleccion('izquierdo')
                        }}
                    >
                        + Asignar Izquierdo</Button>
                </Grid>
                <Grid item container lg={6} sm={6} xs={6} sx={{ justifyContent: 'center' }}>
                    {/* Mostrar un boton que al presionar me salga un cuadro de dialogo con una lista de los usuarios que se podrian asignar */}
                    <Button
                        variant="contained"
                        color="success"
                        // size="small"
                        sx={{ border: '2px solid black', width: '100%', padding: '5px' }}
                        onClick={() => {
                            handleOpenDialogSeleccion('derecho')
                        }}
                    >
                        + Asignar Derecho</Button>
                </Grid>


            </Grid>
            {/* Diálogo para mostrar usuarios */}
            <DialogoUsuarios
                open={openDialog}
                onClose={handleCloseDialog}
                tramo={tramoSeleccionado}
                nombre_tramo={tramoActual}
            />

            {/* Diálogo para seleccionar usuario */}
            <DialogoSeleccion
                open={openDialogSeleccion}
                onClose={handleCloseDialogSeleccion}
                nombre_tramo={tramoActualSeleccion}
                idUsuario={props.datos.id}
                idUsuarioMasAncestro={idUsuarioMasAncestro}
            />
        </form>

    )

}
export default Formulario_Usuario